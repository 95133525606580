import React from 'react'
import BlockTitle from '../../../../shared/ui/BlockTitle'

const About = () => {
  return (
    <div id='about' className='mb-14 768:mb-24'>
      <div className='mb-5'>
      <BlockTitle>Немного о нас</BlockTitle>
      </div>
      <div className='font-gilroyLight text-base space-y-4 leading-5'>
        <div>Наша компания — надежный партнер в скупке радиодеталей и ювелирного лома.</div>
        <div>Компания ООО «Уралплата» специализируется на скупке радиодеталей и ювелирного лома, содержащих драгоценные металлы, а также на закупке лома черных и цветных металлов. Работая в этой области на протяжении 15 лет, мы зарекомендовали себя как надежный и проверенный партнер.</div>
        <div>Наша компания проводит глубокую переработку радиоэлектронных компонентов, что позволяет нам устанавливать максимальные закупочные цены на радиодетали и различные материалы с содержанием драгоценных металлов.</div>
        <div>Мы ценим своих клиентов и стремимся сделать сотрудничество с нами максимально удобным. Вам гарантируются высокие закупочные цены и быстрая оплата. Наши опытные специалисты готовы проконсультировать вас по вопросам сырья и помочь получить максимальную прибыль от продажи.</div>
        <div>Мы также предлагаем услуги по демонтажу и вывозу металлоконструкций любой сложности. Наш собственный автопарк, специализированная техника и опытные бригады обеспечивают качественное и оперативное выполнение работ.</div>
      </div>
    </div>
  )
}

export default About