const feedbackData = [
    {
        id: 1,
        avatar: "https://i.ibb.co/ykYjzfj/user.png", // Пример аватара
        name: "Вячеслав Зенков",
        date: "25 сентября 2023",
        text: "Отличное место где можно сдать ненужный лом, отличные цены и вежливый персонал, многократно посещал это место не разу не пожалел"
    },
    {
        id: 2,
        avatar: "https://i.ibb.co/ykYjzfj/user.png", // Пример аватара
        name: "Константин Константин",
        date: "1 августа 2024",
        text: "Все считают до грамма и выплачивают до копейки. Молодцы парни, вежливые."
    },
    {
        id: 3,
        avatar: "https://i.ibb.co/ykYjzfj/user.png", // Пример аватара
        name: "Mr.Bro gazvpol",
        date: "19 июня 2022",
        text: "Рекомендую это место, отзывчивый персонал, реальные цены, хороший прайс, деньги сразу, чесные весы, был не раз, всегда все четко и на уровне!!!!"
    },
    {
        id: 4,
        avatar: "https://i.ibb.co/ykYjzfj/user.png", // Пример аватара
        name: "Спартак",
        date: "7 июня 2022",
        text: "Опытные сотрудники, рассказали и показали что цениться, а что не котируется! По ценникам ориентируются в зависимости от цен на бирже. Привёз радиолома не на оптовую цену, но посчитали ближе к ней! Рекомендую)"
    },
    {
        id: 5,
        avatar: "https://i.ibb.co/ykYjzfj/user.png", // Пример аватара
        name: "Dmitriy Sha",
        date: "30 июля 2023",
        text: "Вроде не «налюбили», сдавал старые платы компьютерные"
    },
    {
        id: 6,
        avatar: "https://i.ibb.co/ykYjzfj/user.png", // Пример аватара
        name: "Георгий Зуземиль",
        date: "1 июня 2022",
        text: "Давно знаю эту компанию,рекомендую) Ценники хорошие"
    },
];

export default feedbackData